<template>
  <div>
    <b-row>
      <b-col
        v-for="key in Object.keys(modules)"
        :key="key"
        sm="4"
      >
        <item
          :module-key="key"
          :module-const="modules[key].key"
          :module-label="getModuleLabel(key)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Item from './components/Item.vue'

export default {
  name: 'List',
  components: {
    Item,
  },
  data: () => ({
    modules: {
      // The official list of the modules ;)
      mailboxModule: { label: 'Moduł poczty e-mail', key: 'MAILBOX' },
      whatsappModule: { label: 'WhatsappModule', key: 'WHATSAPP' },
      offerModule: { label: 'OfferModule', key: 'OFFER' },
      clientMobileModule: { label: 'ClientMobileModule', key: 'CLIENT_MOBILE' },
      pollModule: { label: 'PollModule', key: 'POLL' },
      agreementModule: { label: 'AgreementModule', key: 'AGREEMENT' },
      applicationModule: { label: 'ApplicationModule', key: 'APPLICATION' },
      paymentModule: { label: 'PaymentModule', key: 'PAYMENT' },
      orderModule: { label: 'OrderModule', key: 'ORDER' },
      projectModule: { label: 'ProjectModule', key: 'PROJECT' },
      loyaltyModule: { label: 'LoyaltyModule', key: 'LOYALTY' },
      marketingModule: { label: 'MarketingModule', key: 'MARKETING' },
      campaignModule: { label: 'CampaignModule', key: 'CAMPAIGN' },
      automationModule: { label: 'AutomationModule', key: 'AUTOMATION' },
      newsModule: { label: 'NewsModule', key: 'NEWS' },
      chatGptModule: { label: 'ChatGptModule', key: 'CHAT_GPT' },
      imageRecognitionModule: { label: 'ImageRecognitionModule', key: 'IMAGE_RECOGNITION' },
      complaintModule: { label: 'ComplaintModule', key: 'COMPLAINT' },
      bookingModule: { label: 'BookingModule', key: 'BOOKING' },
      clientBrowserModule: { label: 'clientBrowserModule', key: 'CLIENT_BROWSER' },
      callModule: { label: 'callModule', key: 'CALL' },
      shortcutModule: { label: 'shortcutModule', key: 'SHORTCUT' },
      migrationModule: { label: 'migrationModule', key: 'MIGRATION' },
    },
  }),
  mounted() {
    if (!this.checkRequiredPermissions([this.$roles.FULL_ACCESS])) {
      this.$router.push({ name: 'dashboard' })
    }
  },
  methods: {
    getModuleLabel(key) {
      return this.$i18n.t(this.modules[key].label)
    },
  },
}
</script>

<style scoped>

</style>
